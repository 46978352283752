import React from 'react'

import { AiFillInstagram } from "react-icons/ai";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
export default function Footer() {
  return (
    <>
      <footer class="footer bg-light">
        <div class="social">
            <a href="https://instagram.com/calcuvedicacademy?utm_source=qr&igshid=MThlNWY1MzQwNA==" target="_blank" rel="noopener noreferrer"><AiFillInstagram size="2em"/></a>
            <a href="tel:9871864910"><IoCall size="2em"/></a>
            <a href="mailto:calcuvedicacademy@gmail.com" target="_blank" rel="noopener noreferrer" ><IoMdMail size="2em" /></a>
            {/* <a href="#"><i class="fab fa-linkedin fa-2x"></i></a> */}
        </div>
        <p>Copyright &copy; 2023 - CalcuVedic</p>
        <p>Developed &amp; Managed by - Nikhil Goyal</p>
    </footer>
    </>
  )
}
