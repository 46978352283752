import React from 'react'
import '../Css/Header.css';
 import logo from '../Assets/logo-no-background.png';
export default function Header() {
  return (
    <>
        <header class="hero" id="home">
        <div id="navbar" class="navbar top">
            <div className='logo'>
            <img src={logo} alt=""/>
            <h1 class="logo-txt">   
                <span class=""> 
                Calcu</span>Vedic Academy
            </h1>
            </div>
            
            <nav>
               
               {/* <span><img src={logo}/></span>  */}
                <ul>
                    <li><a href="#home">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#career-us-section">Careers</a></li>
                    {/* <li><a href="#cases">Cases</a></li> */}
                    
                    <li><a href="#team">Team</a></li>
                    <li><a href="#contact"><b>Register</b></a></li>
                </ul>
               
            </nav>
        </div>

        <div class="content ">
            <h1><b>Making Maths Easy</b> </h1>
            <h1>for Everyone</h1>
            <br/>
            <h2>Our approach to developing Minds</h2><br/>
            <a href="#about" class="btn btn-danger"><i class="fas fa-chevron-right"></i> Register(Coming Soon)</a>
        </div>
    </header>
    </>
  )
}
