import React from "react";
// import video1 from '../Assets/video1.mp4';
import banner2 from "../Assets/Banner2.png";
import '../Css/about.css';
export default function AboutUs() {
  return (
    <>
    <div id="about-section">
    <div class="about-image">
    <img src={banner2} alt="bannerimage" />
    </div>
    <div class="about-info">
    <h2>
    <b>About Us</b>
    <br/>
    <br/>
  </h2>
  <p>
    At our institute, we think that the systematic methods of the
    Abacus and the rich legacy of Vedic mathematics can help to
    unlock the enormous potential of young minds. Our committed team
    of seasoned educators is committed to provide a comprehensive
    educational programme that develops students' mental and
    mathematical skills. 
    <br/>
    Our Vedic Maths programme, which has its
    roots in ancient Indian mathematical principles, gives pupils
    the confidence to enjoy mathematics and the mental and practical
    skills to complete challenging calculations. Our goal with this
    method is to improve students' critical thinking,
    problem-solving, and math skills. 
    <br/>
    Furthermore, by utilising the
    power of bead manipulation and visualisation, our Abacus
    training style concentrates on building a solid foundation in
    mathematics. This method helps develop a child's focus, recall,
    and fluency with numbers.
    <br/>
    <br/>
    Come, explore the world of numbers with us!

  </p>
      <a href="/" class="btn  btn-primary">
      {/* <i class="fas fs-chevron"></i> */}
      Click here to Register (Coming Soon)
    </a>

    </div>

  </div>
      
    </>
  );
}
