import React from "react";
import banner3 from '../Assets/Banner3.png'
import '../Css/career.css';
export default function Careers() {
  return (
    <>
    <div id="career-us-section">

    <div class="career-us-image">
    <img src={banner3} alt="" width="808" height="458" />
    </div>

    <div class="career-us-info">

    <h2>
    <b>Careers at Calcuvedic</b></h2>
  <p>
   <h2>---We are Hiring---</h2>
   <h2>--Experienced Faculties & Freshers--</h2>
   
  </p>
  <a href="/" class="btn btn-secondary" disable>
    {/* <i class="fas fs-chevron"></i> */}
    Join Us (Coming Soon)
  </a>
  <span></span>

    </div>
a
  </div>
    </>
  );
}
