import React from 'react'
import '../Css/video.css';
import Lecture1 from '../Assets/Lecture-1.mp4'
import Lecture2 from '../Assets/Lecture-2.mp4'
import summer from '../Assets/summercamp.MP4'
import devide from '../Assets/devidebynine.MP4'
import multiplication from '../Assets/multiplication.MP4'
import Lecture3 from '../Assets/lecture3.MP4'
import Lecture4 from '../Assets/lecture4.MP4'
function Video() {
  return (
    <div class="gallery-image">
     <div class="img-box">
    <video src={summer} controls>
    </video> 
    </div>
    <div class="img-box">
    <video src={multiplication} controls>
    </video> 
    </div>
    <div class="img-box">
    <video src={devide} controls>
    </video> 
    </div>
    <div class="img-box">
    <video src={Lecture3} controls>
    </video> 
    </div>
    <div class="img-box">
    <video src={Lecture4} controls>
    </video> 
    </div>
    <div class="img-box">
    <video src={Lecture1} controls>
    </video> 
    </div>
    <div class="img-box">
    <video src={Lecture2} controls>
    </video>
    </div>
    </div>

  )
}

export default Video