// import logo from './logo.svg';
import './App.css';
import './Css/lightbox.min.css';
import './Css/style.css';
import './Css/utilities.css';

import Header from  './Components/Header';
import AboutIcons from './Components/AboutIcons';
import Teams from './Components/Team';
import Careers from './Components/Careers';
import AboutUs from './Components/AboutUs';
import Video from './Components/Video';
import Founder from './Components/Founder'
import Footer from './Components/Footer';

function App() {
  return (

      <div>
        <Header/>
        <AboutIcons/>
        <Video/>
        <AboutUs/>
        <Teams/>
        <Founder/>
        <Careers/>
        <Footer/>
      </div>

  );
}


export default App;
