import React from 'react'
import { TbMathSymbols } from "react-icons/tb";
import { PiStudentBold } from "react-icons/pi";
import { FaPaintBrush } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
import { TbAbacus } from "react-icons/tb";

export default function AboutIcons() {
  return (
    <>
         <section id="about" class="icons bg-light">
            <header class="section-header">
               
                <h2><b>What we Offer</b></h2>
                
            </header>  
            <div class="flex-items">
                <div>
                <TbAbacus size="4em" />
                    <div>
                        <h2><b>Abacus</b></h2>
                      {/*<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, vitae.</p>*/}
                    </div>
                </div>
                <div>
                <TbMathSymbols size="4em"/>
                    <div>
                        <h2><b>Vedic Maths</b></h2>
                         {/*<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, vitae.</p>*/}
                    </div>
                </div>
                <div>
                <PiStudentBold size="4em"/>
                    <div>
                        <h2><b>French</b></h2>
                         {/*<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, vitae.</p>*/}
                    </div>
                </div>
                <div>
                <FaPaintBrush size="4em"/>
                    <div>
                        <h2><b>Art & Craft</b></h2>
                         {/*<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, vitae.</p>*/}
                    </div>
                </div>
                <div>
                <FaChalkboardTeacher size="4em"/>
                    <div>
                        <h2><b>Acadmic:Tutions for all subjects</b></h2>
                         {/*<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, vitae.</p>*/}
                    </div>
                </div>
                
            </div>
        </section>
    </>
  )
}
