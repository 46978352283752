import React from 'react'
// import girl from '../Images/members/girl.png';
import shikha from '../Images/members/shikha.jpg';
import shilpa from '../Images/members/shilpa.jpeg';
import shashi from '../Images/members/Shashi.jpg';
import sonia from '../Images/members/Sonia.jpg';
import sarita from '../Images/members/sarita.jpg';
import monisa from '../Images/members/Monisa.jpg';
import ganya from '../Images/members/ganya.jpg';
export default function Team() {
  return (
    <>
     <section id="team" class="team section-padding">
            <header class="section-header">
                {/* <h4>Who we are</h4> */}
                <h2><b>Our Professional Team</b></h2>
                {/* <h2>Currently Hiring</h2> */}
                {/* <p>Be a part of our Professional Team</p> */}
            </header>  
            <div class="flex-items">
                 {/* <div>
                    <img src={shikha} alt=""/>
                    <h3>Mrs. Shikha Nayak</h3>
                    <p>French instructor ( C1 level by Alliance français)</p>
                    <p>M.Sc, B.Ed</p>
                    <p>Experience- 20+ Years</p>
                </div> */}
                <div>
                <img src={shikha} alt=""/>
                    <h3>Mrs. Shikha Nayak</h3>
                    <p>French Instructor </p>
                    <p>M.Sc, B.Ed</p>
                    <p>Experience- 20+ Years</p>
                </div>
                <div>
                <img src={shilpa} alt=""/>
                    <h3>Mrs. Shilpa</h3>
                    <p>Computer Instructor </p>
                    <p>Experience- 10+ Years </p>
                </div>
                <div>
                <img src={shashi} alt=""/>
                    <h3>Mrs. Shashi</h3>
                    <p>Secondary Instructor</p>
                    <p>Experience - 7+ Years </p>
                </div>
                <div>
                <img src={sonia} alt=""/>
                    <h3>Mrs. Sonia Sharma</h3>
                    <p>Instructor</p>
                    <p>MA (Economics) ,NTT</p>
                    <p>Experience- 15+ Years</p>
                </div>
                <div>
                <img src={sarita} alt=""/>
                    <h3>Mrs. Sarita Saini</h3>
                    <p>Instructor</p>
                    <p>B.A.,B.ed., M.A.</p>
                    <p>Experience- 15+ Years</p>
                </div>
            </div> 
            {/* 2nd row */}
            <div class="flex-items">
                 
                <div>
                <img src={monisa} alt=""/>
                    <h3>Mrs. Monisa Das</h3>
                    <p>Art & Craft Instructor </p>
                    <p>Experience- 10+ Years </p>
                </div>
                <div>
                <img src={ganya} alt=""/>
                    <h3>Ms. Ganya</h3>
                    <p>Fresher-Trainee </p>
                    <p></p>
                </div>
                
            </div> 
          </section>   
    </>
  )
}