import React from "react";
// import video1 from '../Assets/video1.mp4';
import nisha from "../Images/members/nisha.jpg";
import "../Css/about.css";
export default function Founder() {
  return (
    <>
      <section id="team" class="team section-padding">
        <header class="section-header">
          {/* <h4>Who we are</h4> */}
          <h2>
            <b>Our Founder</b>
          </h2>
          {/* <h2>Currently Hiring</h2> */}
          {/* <p>Be a part of our Professional Team</p> */}
        </header>
        <div class="flex-items">
          <div>
            <img src={nisha} alt="" />
            
          </div>
          <div class='founder'>
          <h1>Mrs. Nisha Ranjan</h1>
           <p class="founder">Experience - 8 years</p>
            <h2 class="founder">
              Nisha Ranjan is nothing less than a magician.
              <br>

              </br> Her experience with
              children beginning to learn basic concepts of Mathematics makes
              her one of the prolific mentors at Yellow Class. 
              <br></br>She has 8 years
              of experience in Abacus.
              <br></br>
              Her team's mission is to cultivate a love for learning, foster mental agility, and instill confidence in every student. 
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}
